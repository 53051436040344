import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../firebase';
import {
  doc,
  getDoc,
  collection,
  addDoc,
  onSnapshot,
  deleteDoc,
  serverTimestamp
} from 'firebase/firestore';
import { FaRegComment, FaTrashAlt } from 'react-icons/fa';
import './ArticleDetails.css';
import { useAuth } from '../context/AuthContext';

const ArticleDetails = () => {
  const { articleId } = useParams();
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(true);
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  const { currentUser, userProfile } = useAuth();

  useEffect(() => {
    const fetchArticleAndComments = async () => {
      setLoading(true);

      // Fetch the article details
      try {
        const docRef = doc(db, "articles", articleId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            const articleData = docSnap.data();
            setArticle({
                id: docSnap.id,
                title: articleData.title,
                content: articleData.content,
                author: articleData.authorPseudo || 'Unknown',
                publishDate: articleData.publishDate.toDate().toLocaleDateString(),
                coverImage: articleData.coverImage,
                additionalImages: articleData.additionalImages,
            });
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching article: ", error);
      }

      // Fetch the comments
      const commentsCollectionRef = collection(db, 'articles', articleId, 'comments');
      const unsubscribe = onSnapshot(commentsCollectionRef, (snapshot) => {
        const commentsArray = snapshot.docs.map(doc => {
          const data = doc.data();
          return {
            id: doc.id,
            text: data.text,
            authorName: data.authorName || 'Anonyme',
            date: data.timestamp?.toDate().toLocaleString() || 'No date',
            authorId: data.authorId,
          };
        });
        setComments(commentsArray);
        setLoading(false);
      });

      return unsubscribe; // Unsubscribe from listener when component unmounts
    };

    fetchArticleAndComments();
  }, [articleId]);

    const addComment = async () => {
        if (!newComment.trim() || !currentUser) return;
        try {
            await addDoc(collection(db, 'articles', articleId, 'comments'), {
                text: newComment,
                authorId: currentUser.uid,
                authorName: currentUser.displayName || currentUser.email || 'Anonyme',
                timestamp: serverTimestamp(),
            });
            setNewComment("");
        } catch (error) {
            console.error("Error adding comment: ", error);
        }
    };

    const handleDeleteComment = async (commentId) => {
        // Only admins can delete comments
        if (userProfile?.role !== 'admin') {
            alert('You do not have permission to delete this comment.');
            return;
        }
        try {
            await deleteDoc(doc(db, 'articles', articleId, 'comments', commentId));
        } catch (error) {
            console.error("Error deleting comment: ", error);
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!article) {
        return <div>No article found.</div>;
    }


    return (
        <div className="article-details-container">
            <div className="article-metadata">
                <span className="article-author">Par {article.author}</span>
                <span className="article-date">{article.publishDate}</span>
            </div>
            <h1 className="article-title">{article.title}</h1>
            <div className="article-content" dangerouslySetInnerHTML={{ __html: article.content }} />
            {article.coverImage && (
                <img src={article.coverImage} alt="Cover" className="article-cover-image" />
            )}
            {article.additionalImages && article.additionalImages.map((img, index) => (
                <img key={index} src={img} alt={`Additional ${index}`} className="article-additional-image" />
            ))}
            <div className="comments-section">
                <h2>Commentaires <FaRegComment /></h2>
                {comments.map((comment) => (
                    <div key={comment.id} className="comment">
                        <div className="comment-header">
                            <span className="comment-author">{comment.authorName}</span>
                            <span className="comment-date">{comment.date}</span>
                            {userProfile?.role === 'admin' && (
                                <FaTrashAlt onClick={() => handleDeleteComment(comment.id)} />
                            )}
                        </div>
                        <p>{comment.text}</p>
                    </div>
                ))}
                {currentUser && (
                    <div className="comment-form">
                        <input
                            type="text"
                            value={newComment}
                            placeholder="Ajouter un commentaire..."
                            onChange={(e) => setNewComment(e.target.value)}
                        />
                        <button onClick={addComment}>Poster</button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ArticleDetails;
