import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import the CSS for ReactQuill
import { useAuth } from '../context/AuthContext'; // Use the useAuth hook
import { db } from '../firebase'; // Ensure this path is correct
import { collection, addDoc } from 'firebase/firestore';
import './ArticleForm.css';
import { FaPlus, FaMinus } from 'react-icons/fa';

const ArticleForm = () => {
    const { userProfile } = useAuth();
    const isAdmin = userProfile && userProfile.role === 'admin';

    const [article, setArticle] = useState({
        title: '',
        author: '',
        publishDate: '',
        content: '',
        coverImage: '',
        additionalImages: []
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setArticle({ ...article, [name]: value });
    };

    const handleContentChange = (value) => {
        setArticle({ ...article, content: value });
    };

    const handleAdditionalImagesChange = (index, value) => {
        const updatedImages = [...article.additionalImages];
        updatedImages[index] = value;
        setArticle({ ...article, additionalImages: updatedImages });
    };

    const handleAddImageField = () => {
        setArticle({ ...article, additionalImages: [...article.additionalImages, ''] });
    };

    const handleRemoveImageField = (index) => {
        const updatedImages = article.additionalImages.filter((_, i) => i !== index);
        setArticle({ ...article, additionalImages: updatedImages });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!isAdmin) {
            alert("Only administrators can create articles.");
            return;
        }

        try {
            // Add the article to the "articles" collection in Firestore
            const docRef = await addDoc(collection(db, 'articles'), {
                ...article,
                publishDate: article.publishDate ? new Date(article.publishDate) : null,
                additionalImages: article.additionalImages.filter(img => img.trim()) // Remove any empty strings
            });
            console.log("Document written with ID: ", docRef.id);
            // Reset the form here after successful submission
            setArticle({
                title: '',
                author: '',
                publishDate: '',
                content: '',
                coverImage: '',
                additionalImages: []
            });
        } catch (error) {
            console.error("Error adding document: ", error);
        }
    };


    return (
        <form onSubmit={handleSubmit} className="form-container">
        <input
            className="form-input"
            type="text"
            name="title"
            value={article.title}
            onChange={handleChange}
            placeholder="Titre de l'article"
        />
        <input
            className="form-input"
            type="text"
            name="author"
            value={article.author}
            onChange={handleChange}
            placeholder="Nom de l'auteur"
        />
        <input
            className="form-date"
            type="date"
            name="publishDate"
            value={article.publishDate}
            onChange={handleChange}
        />
        <ReactQuill
            className="react-quill"
            value={article.content}
            onChange={handleContentChange}
        />
        <input
            className="form-input"
            type="text"
            name="coverImage"
            value={article.coverImage}
            onChange={handleChange}
            placeholder="URL de l'image de couverture"
        />

            {article.additionalImages.map((image, index) => (
            <div key={index} className="image-url-input">
                <input
                    className="form-input"
                    type="text"
                    value={image}
                    onChange={(e) => handleAdditionalImagesChange(index, e.target.value)}
                    placeholder={`URL of image ${index + 1}`}
                />
                {article.additionalImages.length > 1 && (
                    <FaMinus className="image-url-button" onClick={() => handleRemoveImageField(index)} />
                )}
            </div>
        ))}
        <FaPlus className="image-url-button" onClick={handleAddImageField} />

            <button type="submit" className="form-button">Publier l'article</button>
        </form>
    );
};

export default ArticleForm;
