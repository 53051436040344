// src/context/AuthContext.js
import { useEffect, useState, createContext, useContext } from 'react';
import { getAuth, onAuthStateChanged, signOut, updateProfile as updateFirebaseProfile } from 'firebase/auth';
import { doc, getDoc, updateDoc, getFirestore } from 'firebase/firestore';
import { app } from '../firebase';

const AuthContext = createContext();
const firestore = getFirestore(app);
const auth = getAuth(app);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);
    const [userProfile, setUserProfile] = useState({});

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
          setCurrentUser(user);
          if (user) {
            const userProfileRef = doc(firestore, "users", user.uid);
            const userProfileSnap = await getDoc(userProfileRef);
            if (userProfileSnap.exists()) {
              const userData = userProfileSnap.data();
              setUserProfile({
                ...userData,
                isAdmin: userData.role === 'admin' // Ajoutez un indicateur isAdmin pour faciliter la vérification du rôle
              });
            }
          } else {
            setUserProfile({});
          }
        });
        return unsubscribe;
      }, []);
      

    const logout = () => signOut(auth);

    const updateProfile = async (updates) => {
        if (currentUser) {
            const userRef = doc(firestore, "users", currentUser.uid);
            await updateDoc(userRef, updates);
            setUserProfile(prev => ({ ...prev, ...updates }));
            if (updates.displayName) {
                await updateFirebaseProfile(currentUser, { displayName: updates.displayName });
            }
        }
};

const value = {
    currentUser,
    userProfile,
    logout,
    updateProfile // Ajoutez cette fonction à votre contexte pour l'utiliser dans vos composants
};

return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};