import React, { useState, useEffect, useMemo } from 'react'; // Import useMemo
import { Container, Row, Col, Card, Button, Modal, Form } from 'react-bootstrap';
import { FaPlus, FaEdit, FaTrash, FaInstagramSquare, FaYoutubeSquare, FaGlobeAmericas } from 'react-icons/fa';
import { db } from '../firebase';
import { collection, getDocs, addDoc, updateDoc, deleteDoc, doc } from 'firebase/firestore';
import './Artists.css';
import { useAuth } from '../context/AuthContext'; // Assuming you have the useAuth hook set up

const Artists = () => {
    const [artists, setArtists] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [currentArtist, setCurrentArtist] = useState(null);
    const { userProfile } = useAuth();
    const isAdmin = userProfile?.isAdmin;
    const [currentPage, setCurrentPage] = useState(1);
    const [artistsPerPage] = useState(12);
    const [searchTerm, setSearchTerm] = useState('');

    const [formData, setFormData] = useState({
        name: '',
        role: '',
        imageUrl: '',
        socialLinks: {
            website: '',
            instagram: '',
            youtube: '',
        },
    });

    useEffect(() => {
        const fetchArtists = async () => {
            setLoading(true);
            const artistsCollectionRef = collection(db, 'artists');
            const data = await getDocs(artistsCollectionRef);
            setArtists(data.docs.map(doc => ({ ...doc.data(), id: doc.id })));
            setLoading(false);
        };

        fetchArtists();
    }, []);

    const fetchArtists = async () => {
        setLoading(true);
        const artistsCollectionRef = collection(db, 'artists');
        const data = await getDocs(artistsCollectionRef);
        setArtists(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        setLoading(false);
    };
  const filteredArtists = useMemo(() => artists.filter(artist =>
        artist.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        artist.role.toLowerCase().includes(searchTerm.toLowerCase())
    ), [artists, searchTerm]); // Dépendances

    const indexOfLastArtist = currentPage * artistsPerPage;
    const indexOfFirstArtist = indexOfLastArtist - artistsPerPage;
    const currentArtists = filteredArtists.slice(indexOfFirstArtist, indexOfLastArtist);

    const handleSave = async () => {
        if (!isAdmin) {
            alert('Only admins can modify artist data.');
            return;
        }
        try {
            if (currentArtist) {
                // Update logic
                const artistDocRef = doc(db, 'artists', currentArtist.id);
                await updateDoc(artistDocRef, formData);
            } else {
                // Add new artist logic
                await addDoc(collection(db, 'artists'), formData);
            }
            handleCloseModal();
            await fetchArtists();
        } catch (error) {
            console.error("Error saving artist: ", error);
            alert('There was an error saving the artist data.');
        }
    };
    const handleSocialLinkChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            socialLinks: {
                ...formData.socialLinks,
                [name]: value
            }
        });
    };
    const handleOpenModal = (artist) => {
        if (!isAdmin) {
            alert('Only admins can modify artist data.');
            return;
        }
        if (artist) {
            setCurrentArtist(artist);
            setFormData(artist);
        } else {
            setCurrentArtist(null);
            setFormData({
                name: '',
                role: '',
                imageUrl: '',
                socialLinks: {
                    website: '',
                    instagram: '',
                    youtube: '',
                },
            });
        }
        setShowModal(true);
    };

    const handleDelete = async (artistId) => {
        if (!isAdmin) {
            alert('Only admins can delete artists.');
            return;
        }
        const artistDocRef = doc(db, 'artists', artistId);
        await deleteDoc(artistDocRef);
        fetchArtists(); // Rafraîchit la liste des artistes après la suppression
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setCurrentArtist(null);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    // Ajoutez des méthodes pour gérer l'ajout, l'édition et la suppression ici


    if (loading) return <p>Chargement...</p>;

    return (
        <Container fluid className='artists-container'>
            <Row>
                <Col>
                    <h1>La Galerie des Artistes</h1>
                    <p>Découvrez le cercle des talents remarquables avec lesquels Xavier Flabat a eu l'honneur de partager la scène. Cette galerie célèbre les artistes d'exception dont les collaborations ont marqué les moments forts des représentations, reflétant ainsi la diversité et la richesse de leurs parcours artistiques.</p>
                    <Form className='p-5'>
                        <Form.Group controlId="searchArtists">
                            <Form.Control
                                type="text"
                                placeholder="Rechercher par nom ou tessiture..."
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </Form.Group>
                    </Form>

                    {userProfile?.isAdmin && (
                        <Button className='mb-3' variant="success" onClick={() => handleOpenModal(null)}>
                            <FaPlus /> Ajouter un artiste
                        </Button>
                    )}
                </Col>
            </Row>
            <Row>
                {currentArtists.map((artist) => (
                    <Col key={artist.id} md={3} className="mb-4">
                        <Card className="artist-card">
                            <Card.Img variant="top" src={artist.imageUrl} alt={`Portrait de ${artist.name}`} className="artist-image" />
                            <Card.Body>
                                <Card.Title>{artist.name}</Card.Title>
                                <Card.Text>{artist.role}</Card.Text>
                                <div className="social-links">
                                    {artist.socialLinks.website && (
                                        <a href={artist.socialLinks.website} target="_blank" rel="noopener noreferrer" className="social-icon"><FaGlobeAmericas /></a>
                                    )}
                                    {artist.socialLinks.instagram && (
                                        <a href={artist.socialLinks.instagram} target="_blank" rel="noopener noreferrer" className="social-icon"><FaInstagramSquare /></a>
                                    )}
                                    {artist.socialLinks.youtube && (
                                        <a href={artist.socialLinks.youtube} target="_blank" rel="noopener noreferrer" className="social-icon"><FaYoutubeSquare /></a>
                                    )}
                                </div>
                                {userProfile?.isAdmin && (
                                    <div className="artist-actions">
                                        <Button variant="outline-primary" onClick={() => handleOpenModal(artist)} className="action-button">
                                            <FaEdit /> Modifier
                                        </Button>
                                        <Button variant="outline-danger" onClick={() => handleDelete(artist.id)} className="action-button">
                                            <FaTrash /> Supprimer
                                        </Button>
                                    </div>
                                )}
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
                <div className="pagination">
                    <Button onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>Précédent</Button>
                    <Button onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage === Math.ceil(filteredArtists.length / artistsPerPage)}>Suivant</Button>
                </div>

            </Row>

            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{currentArtist ? 'Éditer' : 'Ajouter'} un artiste</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="name">
                            <Form.Label>Nom</Form.Label>
                            <Form.Control
                                type="text"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                placeholder="Nom de l'artiste"
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="role">
                            <Form.Label>Rôle</Form.Label>
                            <Form.Control
                                type="text"
                                name="role"
                                value={formData.role}
                                onChange={handleChange}
                                placeholder="Rôle de l'artiste"
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="imageUrl">
                            <Form.Label>URL de l'image</Form.Label>
                            <Form.Control
                                type="text"
                                name="imageUrl"
                                value={formData.imageUrl}
                                onChange={handleChange}
                                placeholder="URL de l'image de l'artiste"
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="website">
                            <Form.Label>Site Web</Form.Label>
                            <Form.Control
                                type="text"
                                name="website" // Ensure this matches your state structure
                                value={formData.socialLinks.website}
                                onChange={handleSocialLinkChange}
                                placeholder="URL du site Web de l'artiste"
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="instagram">
                            <Form.Label>Instagram</Form.Label>
                            <Form.Control
                                type="text"
                                name="instagram"
                                value={formData.socialLinks.instagram}
                                onChange={handleSocialLinkChange}
                                placeholder="URL du profil Instagram de l'artiste"
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="youtube">
                            <Form.Label>YouTube</Form.Label>
                            <Form.Control
                                type="text"
                                name="youtube"
                                value={formData.socialLinks.youtube}
                                onChange={handleSocialLinkChange}
                                placeholder="URL de la chaîne YouTube de l'artiste"
                            />
                        </Form.Group>

                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Fermer
                    </Button>
                    <Button variant="primary" onClick={() => handleSave()}>
                        {currentArtist ? 'Sauvegarder les changements' : 'Ajouter'}
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
};

export default Artists;
