// src/components/AdminDashboard.js
import React, { useState, useEffect } from 'react';
import { Card, Table, Button, Pagination, Modal, Form } from 'react-bootstrap';
import { db } from '../firebase';
import { collection, query, getDocs, limit, startAfter, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { FaTrash, FaEdit } from 'react-icons/fa';
import './AdminDashboard.css';
import ArticleForm from './ArticleForm'; // Importez le composant ArticleForm

const AdminDashboard = () => {
    const [users, setUsers] = useState([]);
    const [lastVisible, setLastVisible] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [newRole, setNewRole] = useState('');
    const [showArticleForm, setShowArticleForm] = useState(false); // État pour contrôler l'affichage du formulaire d'article


    useEffect(() => {
        const fetchUsers = async () => {
            setLoading(true);
            const q = query(collection(db, "users"), limit(15));
            const querySnapshot = await getDocs(q);
            const users = [];
            querySnapshot.forEach((doc) => {
                users.push({ id: doc.id, ...doc.data() });
            });
            setUsers(users);
            setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
            setLoading(false);
        };

        fetchUsers();
    }, []);

    const handleNext = async () => {
        setLoading(true);
        const next = query(collection(db, "users"), startAfter(lastVisible), limit(15));
        const querySnapshot = await getDocs(next);
        const nextUsers = [];
        querySnapshot.forEach((doc) => {
            nextUsers.push({ id: doc.id, ...doc.data() });
        });
        setUsers(nextUsers);
        setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
        setLoading(false);
    };

    const handleDeleteUser = async (userId) => {
        if (window.confirm("Êtes-vous sûr de vouloir supprimer cet utilisateur ?")) {
            await deleteDoc(doc(db, "users", userId));
            setUsers(users.filter(user => user.id !== userId));
        }
    };

    const handleOpenChangeRoleModal = (user) => {
        setSelectedUser(user);
        setNewRole(user.role);
        setShowConfirmModal(true);
    };

    const handleCloseConfirmModal = () => {
        setShowConfirmModal(false);
    };

    const handleChangeRole = async () => {
        await updateDoc(doc(db, "users", selectedUser.id), { role: newRole });
        setUsers(users.map(user => user.id === selectedUser.id ? { ...user, role: newRole } : user));
        setShowConfirmModal(false);
    };

    const toggleArticleForm = () => setShowArticleForm(!showArticleForm);

    return (
        <Card className='card-container'>
            <Card.Body>
                <h2 className="text-center mb-4">Tableau de bord Admin</h2>
                <Button variant="primary" onClick={toggleArticleForm}>Créer un article</Button> {/* Bouton pour ouvrir le formulaire d'article */}
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Email</th>
                            <th>Pseudo</th>
                            <th>Rôle</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map((user) => (
                            <tr key={user.id}>
                                <td>{user.email}</td>
                                <td>{user.pseudo}</td>
                                <td>{user.role}</td>
                                <td>
                                    <Button variant="info" onClick={() => handleOpenChangeRoleModal(user)}>
                                        <FaEdit /> Changer le rôle
                                    </Button>{' '}
                                    <Button variant="danger" onClick={() => handleDeleteUser(user.id)}>
                                        <FaTrash /> Supprimer
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                {loading ? "Chargement des utilisateurs..." : (
                    <Pagination>
                        <Pagination.Next onClick={handleNext} disabled={!lastVisible} />
                    </Pagination>
                )}
            </Card.Body>
            <Modal show={showConfirmModal} onHide={handleCloseConfirmModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Changer le rôle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>Choisir un nouveau rôle pour {selectedUser?.email}</Form.Label>
                        <Form.Select
                            value={newRole}
                            onChange={(e) => setNewRole(e.target.value)}>
                            <option value="user">User</option>
                            <option value="admin">Admin</option>
                        </Form.Select>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseConfirmModal}>
                        Annuler
                    </Button>
                    <Button variant="primary" onClick={handleChangeRole}>
                        Confirmer
                    </Button>
                </Modal.Footer>
            </Modal>
             {/* Modal ou autre élément pour afficher le formulaire d'article */}
             <Modal show={showArticleForm} onHide={toggleArticleForm}>
                <Modal.Header closeButton>
                    <Modal.Title>Créer un nouvel article</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ArticleForm />
                </Modal.Body>
            </Modal>
        </Card>
        
    );
};

export default AdminDashboard;
