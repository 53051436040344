    import React from 'react';
    import { Container } from 'react-bootstrap';
    import { FaBalanceScale, FaEdit, FaQuestionCircle, FaRegHandshake } from 'react-icons/fa'; // Added new icons
    import './TermsConditions.css';

    const TermsConditions = () => {
        return (
            <Container fluid className="terms-conditions-container">
                <h1><FaBalanceScale /> Termes et Conditions</h1>
                <section>
                <h2><FaRegHandshake /> Utilisation du Service</h2>
                    <p>
                        En accédant au site web https://xavier-tenor-opera.web.app/, vous acceptez d'être lié par ces termes et conditions, 
                        ainsi que par toutes les lois et réglementations applicables, et vous acceptez que vous êtes responsable du respect 
                        des lois locales applicables. Si vous n'êtes pas d'accord avec l'un de ces termes, vous êtes interdit d'utiliser ou 
                        d'accéder à ce site.
                    </p>
                </section>
                {/* ... Autres sections pour les différentes parties des termes et conditions ... */}
                <section>
                    <h2><FaEdit /> Modifications</h2>
                    <p>
                        Nous nous réservons le droit de modifier ou de remplacer ces termes à tout moment. Tous les changements entreront 
                        en vigueur immédiatement après leur publication sur notre site Web. Votre utilisation continue de notre site après 
                        de telles modifications constituera votre reconnaissance et acceptation des termes et conditions modifiés.
                    </p>
                </section>
                <section>
                    <h2><FaQuestionCircle /> Contactez-Nous</h2>
                    <p>
                        Si vous avez des questions sur ces Termes et Conditions, veuillez nous contacter via email à l'adresse 
                        xavier_flabat@hotmail.com.
                    </p>
                </section>
            </Container>
        );
    };

    export default TermsConditions;
