// src/components/Navbar.js
import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Nav, Navbar as BootstrapNavbar, NavDropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus, faSignInAlt, faUser, faSignOutAlt, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../context/AuthContext';
import './Navbar.css';
import logo from '../assets/logo.png'; // Assurez-vous que le chemin est correct
import { useNavigate } from 'react-router-dom';

const Navbar = ({ onSignupClick, onSignInClick, isAdmin }) => {
    const { currentUser, userProfile, logout } = useAuth();
    const navigate = useNavigate(); // Utilisez useNavigate ici

    const handleLogout = async () => {
        try {
            await logout();
            navigate('/'); // Redirigez après la déconnexion
        } catch (error) {
            console.error("Logout failed: ", error);
        }
    };

    return (
        <BootstrapNavbar className="navbar-opera" expand="lg" sticky="top">
            <Container>
                <BootstrapNavbar.Brand as={Link} to="/">
                    <img
                        src={logo}
                        height="50" // Vous pouvez ajuster la taille selon vos besoins
                        className="d-inline-block align-top"
                        alt="Xavier Opéra Logo"
                    />
                </BootstrapNavbar.Brand>                <BootstrapNavbar.Toggle aria-controls="basic-navbar-nav" />
                <BootstrapNavbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link as={Link} to="/">Accueil</Nav.Link>
                        <Nav.Link as={Link} to="/performances">Performances</Nav.Link>
                        <Nav.Link as={Link} to="/artists">Artistes</Nav.Link>
                        <Nav.Link as={Link} to="/contact">Contact</Nav.Link>
                        {isAdmin && <Nav.Link as={Link} to="/admin">Dashboard Admin</Nav.Link>} {/* Ceci s'affiche uniquement pour les admins */}
                    </Nav>
                    <Nav>
                        {currentUser ? (
                            <>
                                <NavDropdown title={<><FontAwesomeIcon icon={faUser} /> {userProfile.pseudo || currentUser.email}</>} id="basic-nav-dropdown" className="nav-dropdown">
                                    <NavDropdown.Item as={Link} to="/profile" className="dropdown-item-custom">
                                        <FontAwesomeIcon icon={faUserCircle} className="dropdown-item-icon" /> Profil
                                    </NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item onClick={handleLogout} className="dropdown-item-custom">
                                        <FontAwesomeIcon icon={faSignOutAlt} className="dropdown-item-icon" /> Déconnexion
                                    </NavDropdown.Item>
                                </NavDropdown>

                            </>
                        ) : (
                            <>
                                <Nav.Link onClick={onSignupClick}>
                                    <FontAwesomeIcon icon={faUserPlus} /> S'enregistrer
                                </Nav.Link>
                                <Nav.Link onClick={onSignInClick}>
                                    <FontAwesomeIcon icon={faSignInAlt} /> Se connecter
                                </Nav.Link>
                            </>
                        )}
                    </Nav>
                </BootstrapNavbar.Collapse>
            </Container>
        </BootstrapNavbar>
    );
}

export default Navbar;
