import React, { useState, useEffect } from 'react';
import { Carousel, Container, Card, Button } from 'react-bootstrap';
import { db } from '../firebase';
import { collection, getDocs } from 'firebase/firestore';
import './FeaturedArtists.css';
import { FaInstagramSquare, FaYoutubeSquare, FaGlobeAmericas } from 'react-icons/fa';

const FeaturedArtists = () => {
    const [featuredArtists, setFeaturedArtists] = useState([]);

    useEffect(() => {
        const fetchArtists = async () => {
            const artistsCollectionRef = collection(db, 'artists');
            const data = await getDocs(artistsCollectionRef);
            // Mélangez et sélectionnez les premiers 9 artistes pour s'assurer qu'il y a toujours assez d'artistes pour remplir les diapositives
            setFeaturedArtists(data.docs.map(doc => ({ ...doc.data(), id: doc.id })).sort(() => 0.5 - Math.random()).slice(0, 9));
        };

        fetchArtists();
    }, []);

    // Fonction pour diviser les artistes en groupes de 3 pour les diapositives
    const chunkArtists = (artists, size) => {
        return artists.reduce((acc, e, i) => {
          // Calculer l'indice du tableau dans lequel l'élément actuel doit être poussé
          const index = Math.floor(i / size);
          // Si le tableau pour cet indice n'existe pas, le créer
          if (!acc[index]) {
            acc[index] = [];
          }
          // Pousser l'élément actuel dans le tableau approprié
          acc[index].push(e);
          return acc;
        }, []);
      };
      

    return (
        <Container fluid className="featured-artists-container">
            <h2 className="featured-artists-title">Découvrez d'Autres Artistes</h2>
            <p className="featured-artists-text">Un aperçu des talents éblouissants associés à l'opéra.</p>
            <Carousel>
                {chunkArtists(featuredArtists, 3).map((group, idx) => (
                    <Carousel.Item key={idx}>
                        <div className="d-flex justify-content-evenly">
                            {group.map((artist) => (
                                <Card key={artist.id} className="artist-card">
                                    <Card.Img variant="top" src={artist.imageUrl} className="artist-image" />
                                    <Card.Body>
                                        <Card.Title>{artist.name}</Card.Title>
                                        <Card.Text>{artist.role}</Card.Text>
                                        <div className="social-links">
                                            {artist.socialLinks.website && (
                                                <a href={artist.socialLinks.website} target="_blank" rel="noopener noreferrer" className="social-icon"><FaGlobeAmericas /></a>
                                            )}
                                            {artist.socialLinks.instagram && (
                                                <a href={artist.socialLinks.instagram} target="_blank" rel="noopener noreferrer" className="social-icon"><FaInstagramSquare /></a>
                                            )}
                                            {artist.socialLinks.youtube && (
                                                <a href={artist.socialLinks.youtube} target="_blank" rel="noopener noreferrer" className="social-icon"><FaYoutubeSquare /></a>
                                            )}
                                        </div>
                                    </Card.Body>
                                </Card>
                            ))}
                        </div>
                    </Carousel.Item>
                ))}
            </Carousel>
            <Button variant="primary" href="/artists">Voir tous les artistes</Button>
        </Container>
    );
};

export default FeaturedArtists;
