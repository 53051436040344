import React, { useState, useEffect, useCallback } from 'react';
import { Card, Button, Pagination } from 'react-bootstrap';
import { db } from '../firebase';
import { collection, query, getDocs, orderBy, startAfter, limit } from 'firebase/firestore';
import './ArticlesDisplay.css'; // Assurez-vous d'ajouter les styles appropriés
import { useAuth } from '../context/AuthContext'; // Import the useAuth hook to check if the user is admin
import { deleteDoc, doc } from 'firebase/firestore';
import { Link } from 'react-router-dom'; // Importez Link depuis react-router-dom


const ArticlesDisplay = () => {
    const [articles, setArticles] = useState([]);
    const [lastVisible, setLastVisible] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const { userProfile } = useAuth(); // Assuming you have a useAuth hook that provides user info
    const isAdmin = userProfile && userProfile.role === 'admin';
    const PAGE_LIMIT = 3; // Nombre d'articles par page

    const fetchArticles = useCallback(async (page) => {
        let q = query(
            collection(db, 'articles'),
            orderBy('publishDate', 'desc'),
            limit(PAGE_LIMIT)
        );

        if (page > 1 && lastVisible) {
            q = query(q, startAfter(lastVisible));
        }

        const querySnapshot = await getDocs(q);
        const fetchedArticles = [];
        querySnapshot.forEach((doc) => {
            const data = doc.data();
            const dateISO = new Date(data.publishDate.seconds * 1000); // Convert Firestore Timestamp to JavaScript Date object
            fetchedArticles.push({ id: doc.id, ...data, publishDate: dateISO });
        });

        setArticles(fetchedArticles);
        if (!querySnapshot.empty) {
            setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
        }
    }, [lastVisible]);


    useEffect(() => {
        const fetchTotalArticlesCount = async () => {
            const articlesCollection = collection(db, 'articles');
            const articlesSnapshot = await getDocs(articlesCollection);
            const total = articlesSnapshot.size;
            setTotalPages(Math.ceil(total / PAGE_LIMIT));
        };

        fetchTotalArticlesCount();
        fetchArticles(currentPage); // Pass currentPage as a parameter
    }, [currentPage, fetchArticles]);

    const handlePaginationClick = (newPageNumber) => {
        setCurrentPage(newPageNumber);
    };

    const createMarkup = (html) => {
        return { __html: html };
    };

    const handleDeleteArticle = async (articleId) => {
        if (!isAdmin || !window.confirm("Êtes-vous sûr de vouloir supprimer cet article ?")) {
            return;
        }
        // Proceed with deleting the article
        await deleteDoc(doc(db, "articles", articleId));
        // Update the articles state to remove the deleted article
        setArticles(articles.filter(article => article.id !== articleId));
    };

    return (
        <div className='article-cont'>
            <div className="latest-news-container">
                <h2 className="latest-news-title">Actualités de Xavier</h2>
                <p className="latest-news-description">
                    Restez à jour avec les dernières performances, interviews et actualités de Xavier Flabat, ténor d'opéra de renommée internationale. Découvrez ses moments marquants et plongez dans son univers musical.
                </p>
            </div>
            <div className="articles-container pt-5 pb-3">

                {articles.map((article) => (
                    <Card key={article.id} className="article-card">
                        <div className="article-author">De {article.author}</div>
                        <div className="article-date">{article.publishDate.toLocaleDateString()}</div>
                        <Card.Img variant="top" src={article.coverImage} className="article-image" />
                        <Card.Body>
                            <Card.Title>{article.title}</Card.Title>
                            <Card.Text dangerouslySetInnerHTML={createMarkup(article.content.substring(0, 350))} />
                            <Button className='me-2' variant="primary" href={`/articles/${article.id}`}>
                                Lire l'article
                            </Button>
                            {isAdmin && (
                                <>
                                    {/* Utilisez Link pour créer un lien vers la page d'édition de l'article */}
                                    <Link className='me-2' to={`/articles/edit/${article.id}`}><Button variant="secondary">Édition</Button></Link>

                                    {/* Utilisez un lien pour supprimer l'article */}
                                    <Button variant="danger" onClick={() => handleDeleteArticle(article.id)}>Suppression</Button>
                                </>
                            )}
                        </Card.Body>
                    </Card>
                ))}


            </div>
            <Pagination className="pagination-bar">
                <Pagination.First onClick={() => handlePaginationClick(1)} disabled={currentPage === 1} />
                <Pagination.Prev onClick={() => handlePaginationClick(currentPage - 1)} disabled={currentPage === 1} />

                {/* Affichez ici les boutons de pagination en fonction du nombre total de pages */}
                {Array.from({ length: totalPages }, (_, i) => (
                    <Pagination.Item
                        key={i + 1}
                        active={i + 1 === currentPage}
                        onClick={() => handlePaginationClick(i + 1)}
                    >
                        {i + 1}
                    </Pagination.Item>
                ))}

                <Pagination.Next onClick={() => handlePaginationClick(currentPage + 1)} disabled={currentPage === totalPages} />
                <Pagination.Last onClick={() => handlePaginationClick(totalPages)} disabled={currentPage === totalPages} />
            </Pagination>
        </div>
    );
};

export default ArticlesDisplay;
