import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db } from '../firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useAuth } from '../context/AuthContext';
import './ArticleEdit.css';

const ArticleEdit = () => {
    const { id } = useParams();
    const { userProfile } = useAuth();
    const isAdmin = userProfile?.role === 'admin';
    const navigate = useNavigate();
    const [article, setArticle] = useState({
        title: '',
        author: '',
        publishDate: '',
        content: '',
        coverImage: '',
        additionalImages: ['']
    });

    useEffect(() => {
        if (!isAdmin) {
            navigate('/');
            return;
        }
        const fetchArticle = async () => {
            const docRef = doc(db, "articles", id);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                const data = docSnap.data();
                setArticle({
                    id: docSnap.id,
                    ...data,
                    publishDate: data.publishDate?.toDate().toISOString().split('T')[0], // Format date for input
                    additionalImages: data.additionalImages || ['']
                });
            } else {
                console.log("No such document!");
            }
        };

        fetchArticle();
    }, [id, navigate, isAdmin]);

    const handleChange = (field, value) => {
        if (field.startsWith('additionalImages')) {
            const index = parseInt(field.split('[')[1].split(']')[0], 10);
            const updatedImages = [...article.additionalImages];
            updatedImages[index] = value;
            setArticle({ ...article, additionalImages: updatedImages });
        } else {
            setArticle({ ...article, [field]: value });
        }
    };

    const handleAddImageField = () => {
        setArticle({ ...article, additionalImages: [...article.additionalImages, ''] });
    };

    const handleRemoveImageField = (index) => {
        const updatedImages = article.additionalImages.filter((_, i) => i !== index);
        setArticle({ ...article, additionalImages: updatedImages });
    };

    const handleSave = async () => {
        const articleRef = doc(db, "articles", id);
        try {
            await updateDoc(articleRef, {
                ...article,
                publishDate: article.publishDate ? new Date(article.publishDate) : null, // Ensure date is properly formatted for Firestore
                additionalImages: article.additionalImages.filter(img => img.trim()) // Filter out empty strings
            });
            navigate(`/articles/${id}`);
        } catch (error) {
            console.error("Error updating document: ", error);
        }
    };

        return (
            <div className="article-edit-container">
                <h2>Edit Article</h2>
                <form onSubmit={(e) => e.preventDefault()}>
                    <label>Title</label>
                    <input type="text" value={article.title} onChange={(e) => handleChange('title', e.target.value)} />
                    
                    <label>Author</label>
                    <input type="text" value={article.author} onChange={(e) => handleChange('author', e.target.value)} />
                    
                    <label>Publish Date</label>
                    <input type="date" value={article.publishDate.split('T')[0]} onChange={(e) => handleChange('publishDate', e.target.value)} />
                    
                    <label>Content</label>
                    <ReactQuill value={article.content} onChange={(content) => handleChange('content', content)} />
                    
                    <label>Cover Image URL</label>
                    <input type="text" value={article.coverImage} onChange={(e) => handleChange('coverImage', e.target.value)} />
                    
                    {article.additionalImages.map((img, index) => (
                    <div key={index}>
                        <label>{`Additional Image ${index + 1} URL`}</label>
                        <input type="text" value={img} onChange={(e) => handleChange(`additionalImages[${index}]`, e.target.value)} />
                        {index > 0 && (
                            <button type="button" onClick={() => handleRemoveImageField(index)}>Remove</button>
                        )}
                    </div>
                ))}
                <button type="button" onClick={handleAddImageField}>Add Image</button>
                <button onClick={handleSave}>Save Changes</button>
            </form>
            </div>
        );
    };

    export default ArticleEdit;
