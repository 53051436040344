// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyB3d9pFqYZS1ItDso6GoL8BfonRZnMxGZI",
  authDomain: "xavier-tenor-opera.firebaseapp.com",
  projectId: "xavier-tenor-opera",
  storageBucket: "xavier-tenor-opera.appspot.com",
  messagingSenderId: "484209479493",
  appId: "1:484209479493:web:001ea79d66d803b8c3b82c",
  measurementId: "G-69F4GKQ4GN"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);

export { app, analytics, db };
