import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaYoutube, FaInstagram, FaLinkedin } from 'react-icons/fa';
import './Footer.css';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer className="footer">
            <Container>
                <Row>
                    <Col xs={12} md={6} className="footer-content">
                        © 2024 Xavier Flabat - Tous droits réservés
                        <div className="footer-social">
                            <a href="https://www.youtube.com/@xavierflabat365" target="_blank" rel="noopener noreferrer">
                                <FaYoutube />
                            </a>
                            <a href="https://www.instagram.com/xavierflabat/" target="_blank" rel="noopener noreferrer">
                                <FaInstagram />
                            </a>
                        </div>
                    </Col>
                    <Col xs={12} md={6} className="footer-legal">
                        Créé par <a href="https://www.linkedin.com/in/pierre-romain-lopez/" target="_blank" rel="noopener noreferrer">Pierre-Romain LOPEZ</a>
                        <FaLinkedin />
                        <div>
                            <Link to="/privacy-policy">Politique de confidentialité</Link> |
                            <Link to="/terms-and-conditions" className='p-2'>Termes et conditions</Link>
                        </div>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
};

export default Footer;
