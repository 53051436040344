import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db } from '../firebase';
import {
    doc,
    getDoc,
    collection,
    addDoc,
    query,
    orderBy,
    onSnapshot,
    deleteDoc
} from 'firebase/firestore';
import './PerformanceDetail.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faTrash, faShareAlt } from '@fortawesome/free-solid-svg-icons';
// Icônes de marques pour les boutons de partage
import { faTwitter, faFacebook, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { TwitterShareButton, FacebookShareButton, LinkedinShareButton } from 'react-share';
import { useAuth } from '../context/AuthContext';
import { Button, Form, Container, Row, Col, Card, Modal } from 'react-bootstrap';

const PerformanceDetail = () => {
    const { performanceId } = useParams();
    const navigate = useNavigate();
    const [performance, setPerformance] = useState(null);
    const { currentUser, userProfile } = useAuth();
    const [comments, setComments] = useState([]);
    const [newComment, setNewComment] = useState("");
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [commentToDelete, setCommentToDelete] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const commentsPerPage = 5;
    const pageCount = Math.ceil(comments.length / commentsPerPage);
    const indexOfLastComment = currentPage * commentsPerPage;
    const indexOfFirstComment = indexOfLastComment - commentsPerPage;
    const currentComments = comments.slice(indexOfFirstComment, indexOfLastComment);

    useEffect(() => {
        const commentsRef = collection(db, 'performances', performanceId, 'comments');
        const q = query(commentsRef, orderBy("timestamp", "desc"));
      
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
          const commentsArray = [];
          querySnapshot.forEach((doc) => {
            commentsArray.push({ id: doc.id, ...doc.data() });
          });
          setComments(commentsArray);
        }, (error) => {
          // Gestion des erreurs ici
          console.error("Error fetching comments: ", error);
        });
      
        return () => unsubscribe();
      }, [performanceId]);
      

    useEffect(() => {
        const fetchPerformance = async () => {
            const docRef = doc(db, 'performances', performanceId);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                setPerformance(docSnap.data());
            } else {
                console.log('No such document!');
            }
        };

        fetchPerformance();
    }, [performanceId]);

    if (!performance) return <div className="loading">Loading...</div>;

    const shareUrl = window.location.href;
    const title = `${performance.title} by ${performance.author}`;

    const handleAddComment = async (e) => {
        e.preventDefault();
        if (!newComment.trim()) return;

        // Utilisation du pseudo depuis l'état userProfile pour l'ajouter au commentaire
        const pseudo = userProfile?.pseudo || "";

        await addDoc(collection(db, 'performances', performanceId, 'comments'), {
            userId: currentUser.uid,
            userEmail: currentUser.email,
            pseudo: pseudo, // Utilisation du pseudo
            comment: newComment,
            timestamp: new Date(),
        });

        setNewComment(""); // Réinitialiser le champ de texte après soumission
    };

    const confirmDeleteComment = (commentId) => {
        setCommentToDelete(commentId);
        setShowDeleteConfirm(true);
    };

    const deleteComment = async () => {
        if (commentToDelete) {
            await deleteDoc(doc(db, 'performances', performanceId, 'comments', commentToDelete));
            setCommentToDelete(null);
            setShowDeleteConfirm(false);
        }
    };

    const goToNextPage = () => {
        setCurrentPage((prev) => (prev === pageCount ? prev : prev + 1));
    };

    const goToPrevPage = () => {
        setCurrentPage((prev) => (prev === 1 ? prev : prev - 1));
    };

    return (
        <div className="performance-detail-container">
            <div className="performance-header">
                <button onClick={() => navigate(-1)} className="back-button">
                    <FontAwesomeIcon icon={faArrowLeft} /> Retour à la liste
                </button>
                <h2 className="performance-title mt-2">{performance.title} - {performance.author}</h2>
                <div className="performance-date-location">
                    <span>{performance.startDate} - {performance.endDate}</span>
                    <span>{performance.city}, {performance.country}</span>
                </div>
            </div>

            <div className="detail-section">
                <h3>Information Générale</h3>
                <p><strong>Type de performance:</strong> {performance.type}</p>
                <p><strong>Prestataire:</strong> {performance.activityType}</p>
            </div>

            <div className="detail-section">
                <h3>Mise en scène</h3>
                {performance.staging.map((director, index) => (
                    <p key={index}><strong>Mise en scène {index + 1}:</strong> {director.fullName}</p>
                ))}
            </div>

            <div className="detail-section">
                <h3>Direction d'orchestre et Costumes</h3>
                <p><strong>Direction d'orchestre:</strong> {performance.stageDirector}</p>
                <p><strong>Costumes:</strong> {performance.costumeDesigner}</p>
            </div>

            <div className="detail-section">
                <h3>Distribution :</h3>
                {performance.characters.map((character, index) => {
                    const actor = performance.actors[index]; // Trouvez l'acteur correspondant par index
                    return (
                        <p key={`character-actor-${index}`}>
                            <strong>{character.name}</strong> interprété par <strong>{actor.name}</strong>
                        </p>
                    );
                })}
            </div>


            <div className="detail-section">
                <h3>Liens et Ressources</h3>
                <p><strong>Plus d'infos:</strong> <a href={performance.detailsLink} target="_blank" rel="noopener noreferrer">Cliquez ici</a></p>
            </div>

            <div className="performance-share">
                <FontAwesomeIcon icon={faShareAlt} /><span style={{ marginLeft: '8px' }}>Partager cette performance sur les réseaux sociaux:</span>
                <span style={{ marginLeft: '8px' }}></span>
                <TwitterShareButton url={shareUrl} title={title} className="share-button">
                    <FontAwesomeIcon icon={faTwitter} size="2x" style={{ color: "#1DA1F2" }} />
                </TwitterShareButton>
                <FacebookShareButton url={shareUrl} quote={title} className="share-button">
                    <FontAwesomeIcon icon={faFacebook} size="2x" style={{ color: "#1877F2" }} />
                </FacebookShareButton>
                <LinkedinShareButton url={shareUrl} title={title} summary={performance.summary} source={window.location.hostname} className="share-button">
                    <FontAwesomeIcon icon={faLinkedin} size="2x" style={{ color: "#0A66C2" }} />
                </LinkedinShareButton>
            </div>
            <Container className="comments-section mt-4">
                {/* Comments display */}
                <Row className="mt-4">
                {currentComments.map((comment) => (
                        <Col key={comment.id} md={12}>
                            <Card className="mb-2 comment-card">
                                <Card.Header className="comment-card-header">
                                    <div className="comment-user">{comment.pseudo || comment.userEmail}</div>
                                    <div className="comment-date">{comment.timestamp.toDate().toLocaleString()}</div>
                                    {userProfile?.isAdmin && (
                                        <FontAwesomeIcon
                                            icon={faTrash}
                                            onClick={() => confirmDeleteComment(comment.id)}
                                            className="delete-comment-icon"
                                        />
                                    )}

                                </Card.Header>
                                <Card.Body>
                                    <Card.Text>{comment.comment}</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
                <div className="pagination-container">
                    <Button onClick={goToPrevPage} disabled={currentPage === 1}>
                        Précédent
                    </Button>
                    <span>Page {currentPage} sur {pageCount}</span>
                    <Button onClick={goToNextPage} disabled={currentPage === pageCount}>
                        Suivant
                    </Button>
                </div>
                {currentUser && (
                    <Row>
                        <Col>
                            <Form onSubmit={handleAddComment}>
                                <Form.Group controlId="commentTextarea">
                                    <Form.Control
                                        as="textarea"
                                        rows={3}
                                        value={newComment}
                                        onChange={(e) => setNewComment(e.target.value)}
                                        placeholder="Ajoutez un commentaire..."
                                    />
                                </Form.Group>
                                <Button variant="outline-secondary" type="submit" className="mt-2">Poster</Button>
                            </Form>
                        </Col>
                    </Row>
                )}
            </Container>
            <Modal show={showDeleteConfirm} onHide={() => setShowDeleteConfirm(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmer la suppression</Modal.Title>
                </Modal.Header>
                <Modal.Body>Êtes-vous sûr de vouloir supprimer ce commentaire ? Cette action est irréversible.</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteConfirm(false)}>
                        Annuler
                    </Button>
                    <Button variant="danger" onClick={deleteComment}>
                        Supprimer
                    </Button>
                </Modal.Footer>
            </Modal>

        </div>
    );

};

export default PerformanceDetail;
