// Home.js
import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Home.css';
import xavierImage from '../assets/xavier.jpg';
import xavierBioImage from '../assets/xavierbio.jpg';
import xavierMusic from '../assets/xavier.mp3';
import { faMusic, faTimes, faVolumeDown, faVolumeUp } from '@fortawesome/free-solid-svg-icons';
import { useInView } from 'react-intersection-observer';
import YouTubeVideos from './YouTubeVideos';
import ArticlesDisplay from './ArticlesDisplay';
import FeaturedArtists from './FeaturedArtists';

function Home() {
  const [musicPlaying, setMusicPlaying] = useState(false);
  const audioRef = useRef(null);
  const [volume, setVolume] = useState(0.75);
  const [loaded, setLoaded] = useState(false);
  const title = "Xavier FLABAT, Ténor";

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoaded(true);
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  const toggleMusic = () => {
    setMusicPlaying(!musicPlaying);
    musicPlaying ? audioRef.current.pause() : audioRef.current.play();
  };

  const handleVolumeChange = (e) => {
    const newVolume = e.target.value;
    audioRef.current.volume = newVolume;
    setVolume(newVolume);
  };

  const { ref, inView } = useInView({
    threshold: 0.2, // 20% of the element must be visible to trigger the animation
    triggerOnce: true // Trigger only once
  });
  const wrappedTitle = title.split("").map((char, index) =>
    char === " " ? (
      <span key={index}>&nbsp;</span>
    ) : (
      <span key={index} className={`letterAnimation`} style={{ animationDelay: `${index * 0.1}s` }}>
        {char}
      </span>
    )
  );

  return (
    <div className="home-container">
      <div className={`jumbotron home ${loaded ? 'loaded' : ''}`} style={{ backgroundImage: `url(${xavierImage})` }}>
        <h1>{wrappedTitle}</h1>;
        <p>Bienvenue sur le site officiel de Xavier FLABAT, un ténor d'opéra passionné et dévoué, reconnu pour sa contribution à l'art lyrique.</p>
        <Button href="/performances" variant="primary">Voir ses Performances</Button>
        <div className="controls-top-right">
          <div onClick={toggleMusic} className="music-icon">
            {musicPlaying ? (
              <FontAwesomeIcon icon={faTimes} title="Éteindre la musique" />
            ) : (
              <FontAwesomeIcon icon={faMusic} title="Jouer la musique" />
            )}
          </div>
          {musicPlaying && (
            <div className="volume-control">
              <FontAwesomeIcon icon={faVolumeDown} />
              <input
                type="range"
                min="0"
                max="1"
                step="0.01"
                value={volume}
                onChange={handleVolumeChange}
              />
              <FontAwesomeIcon icon={faVolumeUp} />
            </div>
          )}
        </div>
        <audio ref={audioRef} src={xavierMusic} loop />
      </div>
      <section ref={ref} className={`bio-section ${inView ? 'slideIn' : ''}`}>
        <img src={xavierBioImage} alt="Xavier Flabat" className="bio-image" />
        <div className="bio-text">
          <h2>La Carrière de Xavier Flabat</h2>
          <p>
            Xavier Flabat commence sa formation vocale au Conservatoire de Lille. Il suit ensuite
            les enseignements de la mezzo-soprano Christine Solhosse et du ténor Thierry Dran.
            Actuellement, Xavier se perfectionne auprès de la mezzo-soprano Mariam Sarkissian, à Paris
            et du baryton Jeremy Carpenter, à Stockholm.<br /><br />

            À l’Opéra Royal de Wallonie, à Liège, il incarne le Prince Charmant dans Cendrillon de
            Pauline Viardot, et tout récemment, Hermosa dans l’île de Tulipatan d’Offenbach.
            Passionné par le répertoire français, il interprète Faust et recrée le rôle du
            contrebandier Scopetto dans la Sirène d’Auber au Théâtre Impérial de Compiègne. Il est
            encore le chevalier Des Grieux dans Manon de Massenet. Au Théâtre de Reims, il tient le rôle
            d’Octave, le Magnifique, dans l’opéra éponyme de Grétry, compositeur duquel il avait déjà
            interprété un autre rôle-titre, dans l’Amant Jaloux.<br /><br />

            Il endossait l’an dernier le rôle de Don Jose dans Carmen de Bizet – qu’il retrouvera cet
            été au Festival de Toûno en Suisse - et cette saison, celui d’Hoffman dans les Contes
            d’Hoffmann.<br /><br />

            Il est aussi régulièrement invité par des formations orchestrales, par l’orchestre du Val
            de Sambre notamment, ainsi que par l’orchestre philharmonique royal de Liège, celui de
            Fribourg ou encore celui de Bruxelles.<br /><br />

            Cela lui permet de prendre part à des pièces de Rossini, telles que le Stabat Mater ou
            la Petite Messe Solennelle, ou plusieurs messes de Dvorak, Von Suppé, Franck et Mendelssohn.
            L’opérette lui est également familière puisque sur ces dernières années, il fut tour à
            tour, Paganini dans l’œuvre de Léhar, Piquillo dans la Périchole, Pâris dans la Belle Hélène,
            Gontran de Solanges dans les Mousquetaires au couvent, Guy Florès dans l’Auberge du Cheval
            Blanc, Johann Strauss dans Valse de Vienne ou encore le Prince Sou-Chong du Pays du Sourire
            pour ne citer que quelques-uns de ses rôles de ce répertoire.
          </p>
        </div>
      </section>
      <YouTubeVideos />
      <ArticlesDisplay />
      <FeaturedArtists />
    </div>
  );
}

export default Home;
