import React from 'react';
import { Container } from 'react-bootstrap';
import { FaEnvelope, FaInfoCircle } from 'react-icons/fa'; // Assuming these icons are suitable
import './PrivacyPolicy.css'; // Make sure to reference your CSS file correctly

const PrivacyPolicy = () => {
    return (
        <Container fluid className="privacy-policy-container">
            <h1>Politique de Confidentialité</h1>
            <p>Date de dernière mise à jour: 02/04/2024</p>
            <section>
                <h2><FaInfoCircle /> Introduction</h2>
                <p>
                    Pierre-Romain LOPEZ opère le site web https://xavier-tenor-opera.web.app/.
                    Cette page vous informe de nos politiques concernant la collecte, l'utilisation et la divulgation des données personnelles
                    lorsque vous utilisez notre Service et des choix que vous avez associés à ces données.
                </p>
            </section>

            <section>
                <h2>Collecte et Utilisation des Informations</h2>
                <p>
                    Nous collectons plusieurs types différents d'informations à diverses fins pour fournir et améliorer notre Service.
                </p>
                <p>
                    Adresse e-mail et pseudo pour les utilisateurs enregistrés.
                </p>
            </section>

            <section>
                <h2>Droits de l'utilisateur</h2>
                <p>
                    Conformément à la réglementation, vous disposez de droits concernant vos données,
                    comme le droit d'accès, de rectification ou de suppression de vos données personnelles.
                </p>
            </section>

            <section>
                <h2>Modifications de Cette Politique de Confidentialité</h2>
                <p>
                    Nous pouvons mettre à jour notre Politique de Confidentialité de temps à autre.
                    Nous vous notifierons de tout changement en publiant la nouvelle Politique de Confidentialité sur cette page.
                </p>
            </section>

            <section>
                <h2><FaEnvelope /> Contactez-Nous</h2>
                <p>
                    Si vous avez des questions sur cette Politique de Confidentialité, veuillez nous contacter à <a href="mailto:xavier_flabat@hotmail.com">xavier_flabat@hotmail.com</a>.
                </p>
            </section>
        </Container>
    );
};

export default PrivacyPolicy;
