// Importing necessary hooks and components
import React, { useState } from 'react';
import { Form, Button, Container, Row, Col, Alert } from 'react-bootstrap';
import { useAuth } from '../context/AuthContext';
import './Contact.css';
import backgroundImage from '../assets/contact.webp'; // Adjust the path as necessary

function Contact() {
    const { currentUser, userProfile } = useAuth();
    // No need for an email state here since we're using currentUser's email
    const [message, setMessage] = useState('');
    const [showAlert, setShowAlert] = useState(false);

    const handleSendEmail = (e) => {
        e.preventDefault();

        // Check if user is logged in
        if (!currentUser) {
            setShowAlert(true);
            return;
        }

        // Preparing email details
        const userEmail = currentUser.email; // or currentUser?.email if you're checking for null
        const subject = encodeURIComponent(`Nouveau message de ${userProfile?.pseudo || userEmail}`);
        const body = encodeURIComponent(message);

        // Opening the default mail client
        window.open(`mailto:xavier_flabat@hotmail.com?subject=${subject}&body=${body}`);
    };

    return (
        <Container fluid className="contact-container" style={{ backgroundImage: `url(${backgroundImage})` }}>
        <Row className="justify-content-md-center">
                <Col md={12}>
                    <h2>Voulez-vous contacter Xavier ?</h2>
                    <p>Écrivez-lui un message{userProfile?.pseudo ? `, ${userProfile.pseudo}` : ''}.</p>
                    <Form onSubmit={handleSendEmail}>
                        <Form.Group controlId="formBasicMessage">
                            <Form.Label>Message</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                placeholder="Votre message"
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                            />
                        </Form.Group>

                        <Button variant="primary" type="submit">
                            Envoyer
                        </Button>
                    </Form>

                    {showAlert && (
                        <Alert variant="danger" onClose={() => setShowAlert(false)} dismissible className="mt-3">
                            Vous devez être connecté pour envoyer un message.
                        </Alert>
                    )}
                </Col>
            </Row>
        </Container>
    );
}

export default Contact;
