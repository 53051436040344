import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { db } from '../firebase';
import { collection, getDocs, deleteDoc, doc, addDoc, updateDoc } from 'firebase/firestore';
import { useAuth } from '../context/AuthContext';
import { Button, Modal, Form, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import './PerformanceList.css';

const PerformanceList = () => {
    const [performances, setPerformances] = useState([]);
    const { userProfile } = useAuth();
    const isAdmin = userProfile && userProfile.role === 'admin';
    const [showModal, setShowModal] = useState(false);
    const [currentPerformance, setCurrentPerformance] = useState(null);
    const currentDate = new Date();
    const [formData, setFormData] = useState({
        type: '',
        title: '',
        author: '',
        city: '',
        country: '',
        detailsLink: '',
        activityType: '',
        staging: [{ fullName: '' }],
        conductors: '',
        performanceDate: '',
        stageDirector: '',
        costumeDesigner: '',
        choirName: '',
        startDate: '',
        endDate: '',
        characters: [{ name: '' }],
        actors: [{ name: '' }],
        role: '',
    });
    const pastPerformances = performances.filter(performance =>
        new Date(performance.endDate).getTime() < currentDate.getTime()
    );

    const upcomingPerformances = performances.filter(performance =>
        new Date(performance.startDate).getTime() > currentDate.getTime()
    );
    useEffect(() => {
        const fetchPerformances = async () => {
            const snapshot = await getDocs(collection(db, 'performances'));
            const performancesData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setPerformances(performancesData);
        };

        fetchPerformances();
    }, []);

    const handleDelete = async (id) => {
        if (window.confirm('Are you sure you want to delete this performance?')) {
            await deleteDoc(doc(db, 'performances', id));
            setPerformances(performances.filter(performance => performance.id !== id));
        }
    };

    const openModal = (performance = null) => {
        setCurrentPerformance(performance);
        setFormData(performance ? { ...performance, staging: performance.staging || [{ fullName: '' }] } : {
            type: '',
            title: '',
            author: '',
            city: '',
            country: '',
            detailsLink: '',
            activityType: '',
            staging: [{ fullName: '' }],
            conductors: '',
            performanceDate: '',
            stageDirector: '',
            costumeDesigner: '',
            choirName: '',
            startDate: '',
            endDate: '',
            characters: [{ name: '' }],
            actors: [{ name: '' }],
            role: '',
        });
        setShowModal(true);
    };

    const closeModal = () => {
        setCurrentPerformance(null);
        setShowModal(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name.startsWith('staging')) {
            const index = parseInt(name.split('-')[1], 10);
            const newStaging = formData.staging.map((item, i) => i === index ? { ...item, fullName: value } : item);
            setFormData({ ...formData, staging: newStaging });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const handleSave = async () => {
        const performanceData = { ...formData };
        if (currentPerformance) {
            await updateDoc(doc(db, 'performances', currentPerformance.id), performanceData);
        } else {
            await addDoc(collection(db, 'performances'), performanceData);
        }
        closeModal();
        // Refresh the performances list to show the updated data
        // Note: You might need to call fetchPerformances here or use a state update mechanism
    };

    const handleRemoveStagingField = (indexToRemove) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            staging: prevFormData.staging.filter((_, index) => index !== indexToRemove),
        }));
    };

    const handleAddStagingField = () => {
        setFormData(prevState => ({
            ...prevState,
            staging: [...prevState.staging, { fullName: '' }]
        }));
    };

    const handleChangeStaging = (index, value) => {
        const newStaging = formData.staging.map((item, i) => i === index ? { ...item, fullName: value } : item);
        setFormData({ ...formData, staging: newStaging });
    };

    const handleCharacterChange = (index, value) => {
        let updatedCharacters = formData.characters.map((item, i) => {
            if (i === index) {
                return { ...item, name: value };
            }
            return item;
        });

        setFormData(prevFormData => ({
            ...prevFormData,
            characters: updatedCharacters
        }));
    };

    const handleActorChange = (index, value) => {
        let updatedActors = formData.actors.map((item, i) => {
            if (i === index) {
                return { ...item, name: value };
            }
            return item;
        });

        setFormData(prevFormData => ({
            ...prevFormData,
            actors: updatedActors
        }));
    };

    const handleAddCharacterActor = () => {
        setFormData(prevFormData => ({
            ...prevFormData,
            characters: [...prevFormData.characters, { name: '' }],
            actors: [...prevFormData.actors, { name: '' }]
        }));
    };

    const handleRemoveCharacterActor = (index) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            characters: prevFormData.characters.filter((_, i) => i !== index),
            actors: prevFormData.actors.filter((_, i) => i !== index)
        }));
    };

    return (

        <div>
            <div className="introduction-section">
                <h1 className="page-title">Calendrier des Performances</h1>
                <p className="page-description">
                    Découvrez le calendrier complet des événements à venir et revivez les moments forts des performances passées.
                    Que ce soit des opéras, des concerts ou des spectacles, explorez la richesse de notre programmation artistique.
                </p>
            </div>

            {isAdmin && (
                <Button onClick={() => openModal(null)} className="add-performance-button">
                    Ajouter une performance
                </Button>
            )}
            <div className="performance-list-container pt-5">
                <section className="upcoming-performances-section">
                    <h2 className="section-title">Événements à venir</h2>
                    <div className="performance-cards-container">
                        {upcomingPerformances.map((performance) => (
                            <div key={performance.id} className="performance-card">
                                <div className="performance-header">
                                    <span className="performance-title">{performance.title}</span>
                                    <span className="performance-author">{performance.author}</span>
                                    <span className="performance-date">{performance.performanceDate}</span>
                                </div>
                                <div className="performance-content">
                                    <span className="performance-activityType">{performance.activityType}</span>
                                    <span className="performance-role">{(performance.role)}</span>
                                    <Link to={`/performances/${performance.id}`} className="performance-details-link">Voir la distribution et l'équipe</Link>
                                </div>

                                {isAdmin && (
                                    <div className="performance-actions">
                                        <Button onClick={() => openModal(performance)} className="edit-button">
                                            <FontAwesomeIcon icon={faEdit} className="edit-icon" />
                                        </Button>
                                        <Button variant="danger" onClick={() => handleDelete(performance.id)} className="delete-button">
                                            <FontAwesomeIcon icon={faTrash} className="delete-icon" />
                                        </Button>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </section>

                <section className="past-performances-section">
                    <h2 className="section-title">Événements finis</h2>
                    <div className="performance-cards-container">
                        {pastPerformances.map((performance) => (
                            <div key={performance.id} className="performance-card">
                                <div className="performance-header">
                                    <span className="performance-title">{performance.title}</span>
                                    <span className="performance-author">{performance.author}</span>
                                    <span className="performance-date">{performance.performanceDate}</span>
                                </div>
                                <div className="performance-content">
                                    <span className="performance-activityType">{performance.activityType}</span>
                                    <span className="performance-role">{(performance.role)}</span>
                                    <Link to={`/performances/${performance.id}`} className="performance-details-link">Voir la distribution et l'équipe</Link>
                                </div>
                                {isAdmin && (
                                    <div className="performance-actions">
                                        <Button onClick={() => openModal(performance)} className="edit-button">
                                            <FontAwesomeIcon icon={faEdit} className="edit-icon" />
                                        </Button>
                                        <Button variant="danger" onClick={() => handleDelete(performance.id)} className="delete-button">
                                            <FontAwesomeIcon icon={faTrash} className="delete-icon" />
                                        </Button>
                                    </div>
                                )}

                            </div>
                        ))}
                    </div>
                </section>
            </div>

            <Modal show={showModal} onHide={closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{currentPerformance ? 'Edit Performance' : 'Add Performance'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            <Col md={6}>
                                <Form.Group controlId="type">
                                    <Form.Label>Type de performance</Form.Label>
                                    <Form.Control as="select" name="type" value={formData.type} onChange={handleChange}>
                                        <option value="Opera">Opera</option>
                                        <option value="Operetta">Operetta</option>
                                        <option value="Other">Autre</option>
                                    </Form.Control>
                                    {formData.type === 'Other' && (
                                        <Form.Control
                                            type="text"
                                            name="customType"
                                            placeholder="Entrez un type personnalisé"
                                            value={formData.customType}
                                            onChange={handleChange}
                                            className="mt-2"
                                        />
                                    )}
                                </Form.Group>

                            </Col>
                            <Col md={6}>
                                <Form.Group controlId="title">
                                    <Form.Label>Nom de la pièce</Form.Label>
                                    <Form.Control type="text" name="title" value={formData.title} onChange={handleChange} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Form.Group controlId="author">
                                    <Form.Label>Auteur de la pièce</Form.Label>
                                    <Form.Control type="text" name="author" value={formData.author} onChange={handleChange} />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId="role">
                                    <Form.Label>Role de Xavier</Form.Label>
                                    <Form.Control type="text" name="role" value={formData.role} onChange={handleChange} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Form.Group controlId="city">
                                    <Form.Label>Ville</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="city"
                                        value={formData.city}
                                        onChange={handleChange}
                                        placeholder="Entrez la ville"
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId="country">
                                    <Form.Label>Pays</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="country"
                                        value={formData.country}
                                        onChange={handleChange}
                                        placeholder="Entrez le pays"
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={6}>
                                <Form.Group controlId="activityType">
                                    <Form.Label>Prestataire</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="activityType"
                                        value={formData.activityType}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId="detailsLink">
                                    <Form.Label>Liens Médias URL</Form.Label>
                                    <Form.Control type="text" name="detailsLink" value={formData.detailsLink} onChange={handleChange} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                {formData.staging.map((director, index) => (
                                    <Row key={index} className="form-row-aligned-items">
                                        <Col md={10}>
                                            <Form.Group controlId={`staging-${index}`}>
                                                <Form.Label>Mise en scène {index + 1}</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name={`staging-${index}`}
                                                    value={director.fullName}
                                                    onChange={(e) => handleChangeStaging(index, e.target.value)}
                                                    placeholder="Prénom Nom"
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={2} className="d-flex align-items-center">
                                            <Button variant="danger" onClick={() => handleRemoveStagingField(index)} className="mx-auto">
                                                <FontAwesomeIcon icon={faMinusCircle} />
                                            </Button>
                                        </Col>
                                    </Row>

                                ))}
                                <Button variant="secondary" onClick={handleAddStagingField} className="mt-2">
                                    Ajouter un autre metteur en scène
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Form.Group controlId="conductors">
                                    <Form.Label>Chef d'orchestre</Form.Label>
                                    <Form.Control type="text" name="conductors" value={formData.conductors} onChange={handleChange} />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId="stageDirector">
                                    <Form.Label>Direction d'orchestre</Form.Label>
                                    <Form.Control type="text" name="stageDirector" value={formData.stageDirector} onChange={handleChange} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Form.Group controlId="startDate">
                                    <Form.Label>Date de début</Form.Label>
                                    <Form.Control
                                        type="date"
                                        name="startDate"
                                        value={formData.startDate}
                                        onChange={handleChange} />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId="endDate">
                                    <Form.Label>Date de fin</Form.Label>
                                    <Form.Control
                                        type="date"
                                        name="endDate"
                                        value={formData.endDate}
                                        onChange={handleChange} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Form.Group controlId="costumeDesigner">
                                    <Form.Label>Costumes</Form.Label>
                                    <Form.Control type="text" name="costumeDesigner" value={formData.costumeDesigner} onChange={handleChange} />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId="choirName">
                                    <Form.Label>Chœur</Form.Label>
                                    <Form.Control type="text" name="choirName" value={formData.choirName} onChange={handleChange} />
                                </Form.Group>
                            </Col>
                        </Row>
                        {formData.characters.map((character, index) => (
                            <Row key={`character-actor-row-${index}`}>
                                <Col md={5}>
                                    <Form.Group controlId={`character-${index}`}>
                                        <Form.Label>Personnage</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name={`character-${index}`}
                                            value={character.name}
                                            onChange={(e) => handleCharacterChange(index, e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={5}>
                                    <Form.Group controlId={`actor-${index}`}>
                                        <Form.Label>Comédien</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name={`actor-${index}`}
                                            value={formData.actors[index]?.name}
                                            onChange={(e) => handleActorChange(index, e.target.value)}
                                        />

                                    </Form.Group>
                                </Col>
                                <Col md={2} className="d-flex align-items-center">
                                    <Button variant="danger" onClick={() => handleRemoveCharacterActor(index)}>
                                        Remove
                                    </Button>
                                </Col>
                            </Row>
                        ))}

                        <Row>
                            <Col md={12}>
                                <Button variant="secondary" onClick={handleAddCharacterActor} className="mt-2">
                                    Ajouter Personnage et Comédien
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeModal}>Cancel</Button>
                    <Button variant="primary" onClick={handleSave}>Save</Button>
                </Modal.Footer>
            </Modal>


        </div>
    );
};

export default PerformanceList;

