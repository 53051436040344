import React from 'react';
import './YouTubeVideos.css'; // Assurez-vous que ce chemin d'importation est correct

function YouTubeVideos() {
    return (
        <section className="youtube-section">
            <h2 className="youtube-section-title">Performances sur YouTube</h2>
            <p className="youtube-introduction">
                Découvrez les interprétations captivantes de Xavier Flabat sur scène,
                disponibles sur sa chaîne YouTube. Plongez dans l'univers lyrique de ce
                ténor exceptionnel et vivez l'opéra comme si vous y étiez.
            </p>
            <div className="youtube-videos-container">
                <div className="youtube-video-card">
                    <iframe
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/LjE6f4AmKP0"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                    <h3>Xavier Flabat - Air de Don Jose "La fleur que tu m'avais jetée" - Carmen (G. Bizet)
</h3>
                </div>
                <div className="youtube-video-card">
                    <iframe
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/Vb11s79mlig"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                    <h3>Petite Messe Solennelle de Rossini - "Domine Deus"
</h3>
                </div>
                <div className="youtube-video-card">
                    <iframe
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/8faH--qFpQU"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                    <h3>Légende de Kleinzach - Les Contes d'Hoffmann (Offenbach) "Il était une fois à la cour d'Eisenach"
</h3>
                </div>
                <div className="youtube-video-card">
                    <iframe
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/T5ReEStUodI"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                    <h3>Les Mousquetaires au couvent (L. Varney) - Trio "Une femme"
</h3>
                </div>
            </div>
        </section>
    );
}

export default YouTubeVideos;
