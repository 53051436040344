import React, { useState } from 'react';
import { Modal, Button, Form, Alert } from 'react-bootstrap';
import { getAuth, signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { FaGoogle, FaEnvelope, FaLock } from 'react-icons/fa'; // Importez les icônes nécessaires
import './SignInModal.css'; // Assurez-vous que le chemin est correct

const SignInModal = ({ show, handleClose }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('danger'); // 'success' pour succès, 'danger' pour erreur

  const auth = getAuth();

  const handleSignIn = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      handleClose();
    } catch (error) {
      setMessage("Échec de la connexion : " + error.message);
      setMessageType('danger');
    }
  };

  const handleSignInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
      handleClose();
    } catch (error) {
      setMessage("Échec de la connexion avec Google : " + error.message);
      setMessageType('danger');
    }
  };

  return (
    <Modal show={show} onHide={handleClose} className="signin-modal">
      <Modal.Header closeButton className="signin-modal-header">
        <Modal.Title>Se connecter</Modal.Title>
      </Modal.Header>
      <Modal.Body className="signin-modal-body">
        {message && <Alert variant={messageType}>{message}</Alert>}
        <Form onSubmit={handleSignIn}>
          <Form.Group controlId="signInEmail">
            <Form.Label><FaEnvelope /> Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="Entrez l'email"
              required
              onChange={e => setEmail(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="signInPassword" className='mt-3'>
            <Form.Label><FaLock /> Mot de Passe</Form.Label>
            <Form.Control
              type="password"
              placeholder="Mot de Passe"
              required
              onChange={e => setPassword(e.target.value)}
            />
          </Form.Group>
          <Button variant="primary" type="submit" className="signin-modal-button">
            Se connecter
          </Button>
        </Form>
        <div className="text-center">
          <Button variant="secondary" onClick={handleSignInWithGoogle} className="google-signin-button">
            <FaGoogle /> Se connecter avec Google
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SignInModal;
