// src/components/Profile.js
import React, { useState, useEffect } from 'react';
import { Form, Button, Card, Alert } from 'react-bootstrap';
import { useAuth } from '../context/AuthContext';
import { db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';
import { FaUserEdit, FaLock, FaSave } from 'react-icons/fa'; // Icônes pour les actions
import './Profile.css'; // CSS spécifique à la page Profil
import { updatePassword } from 'firebase/auth'; // Importez updatePassword de Firebase

const Profile = () => {
    const { currentUser, updateProfile } = useAuth();
    const [pseudo, setPseudo] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchUserData = async () => {
            const docRef = doc(db, "users", currentUser.uid);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                setPseudo(docSnap.data().pseudo);
            } else {
                console.log("No such document!");
            }
        };

        fetchUserData();
    }, [currentUser]);

  const handleSubmit = async (e) => {
        e.preventDefault();
        if (loading) return; // Empêche le double envoi

        setLoading(true);
        setError('');
        setMessage('');

        // Vérifiez si le nouveau mot de passe est entré et confirmez qu'il correspond
        if (newPassword && newPassword !== confirmNewPassword) {
            setError("Les mots de passe ne correspondent pas.");
            setLoading(false);
            return;
        }

        const promises = [];
        if (pseudo) {
            promises.push(updateProfile({ pseudo: pseudo })); // Appel de updateProfile pour changer le pseudo
        }
        
        if (newPassword) {
            promises.push(updatePassword(currentUser, newPassword)); // Utilisation de updatePassword pour changer le mot de passe
        }

        Promise.all(promises).then(() => {
            setMessage("Profil mis à jour avec succès.");
        }).catch((error) => {
            setError("Une erreur s'est produite lors de la mise à jour du profil: " + error.message);
        }).finally(() => {
            setLoading(false);
        });
    };

    return (
        <Card className="mx-auto my-4" style={{ maxWidth: '800px' }}> {/* Cette div centrerait le Card */}
            <Card.Body>
            <h2 className="text-center mb-4">Profil de {currentUser.email}</h2>
                {error && <Alert variant="danger">{error}</Alert>}
                {message && <Alert variant="success">{message}</Alert>}
                <Form onSubmit={handleSubmit}>
                    <Form.Group id="pseudo">
                        <Form.Label><FaUserEdit /> Pseudo</Form.Label>
                        <Form.Control
                            type="text"
                            required
                            value={pseudo}
                            onChange={(e) => setPseudo(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group id="newPassword" className='mt-2'>
                        <Form.Label><FaLock /> Nouveau Mot de Passe</Form.Label>
                        <Form.Control
                            type="password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group id="confirmNewPassword" className='mt-2'>
                        <Form.Label><FaLock /> Confirmer Nouveau Mot de Passe</Form.Label>
                        <Form.Control
                            type="password"
                            value={confirmNewPassword}
                            onChange={(e) => setConfirmNewPassword(e.target.value)}
                        />
                    </Form.Group>
                    <Button disabled={loading} className="w-100 mt-3" type="submit">
                        <FaSave /> Enregistrer
                    </Button>
                </Form>
            </Card.Body>
        </Card>
    );
};

export default Profile;
