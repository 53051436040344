// src/components/SignupModal.js
import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Alert, Spinner } from 'react-bootstrap';
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { db } from '../firebase'; // Assurez-vous que ce chemin est correct
import { doc, setDoc } from 'firebase/firestore';
import './SignUpModal.css';
import { FaGoogle, FaUser, FaEnvelope, FaLock } from 'react-icons/fa'; // Importez les icônes nécessaires

const SignupModal = ({ show, handleClose }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [pseudo, setPseudo] = useState(''); // Ajout d'un état pour le pseudo
    const [message, setMessage] = useState(null);
    const auth = getAuth();
    const [loading, setLoading] = useState(false);
    const [messageType, setMessageType] = useState('danger'); // 'success' pour succès, 'danger' pour erreur

    useEffect(() => {
        if (show) {
            setEmail('');
            setPassword('');
            setConfirmPassword('');
            setPseudo('');
            setMessage(null);
        }
    }, [show])

    const handleSignup = async (e) => {
        e.preventDefault();
        setLoading(true);
        if (password !== confirmPassword) {
            setMessage("Les mots de passe ne correspondent pas.");
            setMessageType('danger');
            setLoading(false);
            return;
        }
        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            await sendEmailVerification(userCredential.user);
            // Ajouter le champ role avec la valeur 'user' dans le document Firestore
            await setDoc(doc(db, "users", userCredential.user.uid), {
                email: email,
                pseudo: pseudo, // Utilisez le pseudo fourni
                role: 'user' // Définissez le rôle par défaut sur 'user'
            });
            setMessage("Inscription réussie ! Un e-mail de vérification a été envoyé.");
            setMessageType('success');
            
            setTimeout(() => {
                handleClose();
                setLoading(false);
                setMessage(null);
            }, 2000);
        } catch (error) {
            setMessage("Échec de l'inscription : " + error.message);
            setMessageType('danger');
            setLoading(false);
        }
    };
    
    

    const handleGoogleSignIn = async () => {
        setLoading(true);
        const provider = new GoogleAuthProvider();
        try {
            const result = await signInWithPopup(auth, provider);
            const user = result.user;
            // Ajouter le document avec le rôle dans Firestore pour les utilisateurs s'inscrivant avec Google
            await setDoc(doc(db, "users", user.uid), {
                email: user.email,
                pseudo: pseudo || user.displayName || 'Utilisateur Google',
                role: 'user' // Le rôle par défaut est 'user'
            });
            setMessage("Connexion réussie avec Google !");
            setMessageType('success');
    
            setTimeout(() => {
                handleClose();
                setLoading(false);
                setMessage(null);
            }, 2000);
        } catch (error) {
            setMessage("Échec de la connexion : " + error.message);
            setMessageType('danger');
            setLoading(false);
        }
    };
    


    return (
        <Modal show={show} onHide={handleClose} className="signup-modal">
            <Modal.Header closeButton className="signup-modal-header">
                <Modal.Title>Créer un compte</Modal.Title>
            </Modal.Header>
            <Modal.Body className="signup-modal-body">
            {message && <Alert variant={messageType}>{message}</Alert>}
                <Form onSubmit={handleSignup}>
                    <Form.Group controlId="signupPseudo">
                        <Form.Label><FaUser /> Pseudo</Form.Label>
                        <Form.Control type="text" placeholder="Choisissez un pseudo" required onChange={e => setPseudo(e.target.value)} />
                    </Form.Group>
                    <Form.Group controlId="signupEmail" className='mt-2'>
                        <Form.Label><FaEnvelope /> Email</Form.Label>
                        <Form.Control type="email" placeholder="Entrez l'email" required onChange={e => setEmail(e.target.value)} />
                    </Form.Group>
                    <Form.Group controlId="signupPassword" className='mt-2'>
                        <Form.Label><FaLock /> Mot de Passe</Form.Label>
                        <Form.Control type="password" placeholder="Mot de Passe" required onChange={e => setPassword(e.target.value)} />
                    </Form.Group>
                    <Form.Group controlId="confirmPassword" className='mt-2'>
                        <Form.Label><FaLock /> Confirmer Mot de Passe</Form.Label>
                        <Form.Control type="password" placeholder="Confirmer Mot de Passe" required onChange={e => setConfirmPassword(e.target.value)} />
                    </Form.Group>
                    {loading && <div className="text-center mt-3"><Spinner animation="border" variant="light" /></div>}
                    <Button variant="primary" type="submit" className="signup-modal-button" disabled={loading}>
                        S'enregistrer
                    </Button>
                    <Button variant="secondary" onClick={handleGoogleSignIn} className="google-signin-button" disabled={loading}>
                        <FaGoogle /> S'inscrire avec Google
                    </Button>

                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default SignupModal;
